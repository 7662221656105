
export default {
  name: "Cards",
  props: {
    cards: {
      type: Array,
      required: true,
    },
    darkBg: {
      type: Boolean,
      default: false,
    },
    darker: {
      type: Boolean,
      default: false,
    }
  },
};
